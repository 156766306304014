<template>
  <div class="page-wrapper">
    <div class="title-content">
      <BreadCrumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>

        <el-breadcrumb-item> 印花税测算</el-breadcrumb-item>
      </BreadCrumb>
      <div class="main-top">
        <MainHeader>
          <template slot="title">数据上传</template>
        </MainHeader>
        <SaSteps :steps="steps" :active-index="stepActive"></SaSteps>
        <div class="placeholder"></div>
      </div>
    </div>
    <div class="section-box">
      <div class="invoice-content">
        <img class="invoice-img" src="@/assets/result/no-invoice.png" alt/>
        <div class="invoice-text">数据上传完成，结果生成中</div>
        <!--        <div class="invoice-upload">报告生成后可在“检测记录”内查看下载</div>-->
        <router-link :to="{name:'stampTax-list'}" class="invoice-btn ease">
          {{ timeLeft }}秒后返回检测页面
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import BreadCrumb from "../../../components/BreadCrumb";
import MainHeader from "../../components/MainHeader";
import SaFooter from "../../Test/UploadTable/components/SaFooter";
import SaSteps from "../../components/SaSteps";

export default {
  name: "HeTongTaiZhang",
  components: {
    BreadCrumb,
    MainHeader,
    SaSteps
  },
  data() {
    return {
      steps: ['基础信息', '合同台账上传', '印花税申报表上传', '提交成功'],
      stepActive: 3,
      timeLeft: 3,
      timer: null
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timeLeft = this.timeLeft - 1;
      console.log("count down")
      if (this.timeLeft <= 0) {
        clearInterval(this.timer);

        this.$router.push({name: 'stampTax-list'})
      }
    }, 1000)
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  }

}
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  }

  .main-top {
    display: flex;
    justify-content: space-between;
  }

  .section-box {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom 50px

    .title {
      color: rgba(0, 0, 0, 0.8);
      line-height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #eeeeee;
    }

    .invoice-content {
      text-align: center;
      height: 606px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      margin: 0 30px;
      margin-top: 16px;

      .invoice-img {
        margin-top: 80px;
      }

      .invoice-text {
        margin-top: 24px;
        font-size: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
      }

      .invoice-upload {
        margin-top: 12px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }

      .invoice-btn {
        display inline-block;
        width: 170px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: rgba(47, 84, 235, 1);
        border-radius: 4px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        margin: 0 auto;
        margin-top: 24px;
        cursor: pointer;
      }

      .invoice-btn:hover {
        background: rgba(47, 64, 235, 1);
      }

      .ease {
        transition: all 0.3s;
      }
    }


  }


}
</style>
